import {Game} from './game';

const game = new Game(document.querySelector(".tetris"))
document.addEventListener('keyup', (e) => {
    switch(e.key) {
        case 'ArrowDown':
            game.move('down')
            break
        case 'ArrowRight':
            game.move('right')
            break
        case 'ArrowLeft':
            game.move('left')
            break
        case 'ArrowUp':
            game.hardDrop()
            break
        case ' ': 
            if(game.getGameOver()) {
                game.restart()
            } else {
                game.rotate()
            }
            break
    }
})

document.querySelector('.controller').addEventListener('click', (e) => {
    const t = e.target
    if(t instanceof Element) {
        const k = t.closest('[data-key]')
        if(k) {
            if(game.getGameOver()) {
                game.restart()
                return
            }
            const key = k.getAttribute('data-key')
            switch(key) {
                case 'harddrop':
                    game.hardDrop()
                    break
                case 'rotate':
                    game.rotate()
                    break
                default:
                    game.move(key as any)
            }
        }
        
    }
})

setInterval(() => {
    game.move('down')
}, 1000)