import { tetrimino_type } from "./tetriminos";
import { Matrix } from "./matrix";

export class Field {
    private matrix: Matrix<tetrimino_type>

    constructor(private dim: {height: number, width: number}) {
        this.matrix = new Matrix(dim)
    }

    getAt(x: number, y: number) {
        return this.matrix.getAt(x, y)
    }

    putAt(x: number, y: number, v: tetrimino_type) {
        this.matrix.putAt(x, y, v)
    }

    initDOM(parent: Element) {
        parent.classList.add('tetris')
        const arr: string[] = []
        for(let y = 0; y < this.dim.height; y++) {
            for(let x = 0; x < this.dim.width; x++) {
                arr.push(`<div class="tetris_block" data-pos="${x}-${y}" data-kind=""></div>`)
            }
        }
        parent.insertAdjacentHTML('beforeend', arr.join(""))
    }

    updateDOM(parent: Element) {
        const blocks = parent.querySelectorAll(".tetris_block")
        for(const b of blocks) {
            const parse = (s: string) => Number.parseInt(s, 10)
            const [x, y] = b.getAttribute('data-pos').split('-').map(parse)
            b.setAttribute('data-kind', this.matrix.getAt(x, y) || '')
        }
    }

    checkRange(x: number, y: number) {
        return 0 <= x && x < this.dim.width && y < this.dim.height
    }

    deleteRows(): number {
        let count = 0
        for(let y = 0; y < this.dim.height; y++) {
            if(this.shouldDeleteRow(y)) {
                count++
                for(let _y = y - 1; _y >= 0; _y--) {
                    this.shiftRow(_y)
                }
                for(let x = 0; x < this.dim.width; x++) {
                    this.putAt(x, 0, null)
                }
            }
        }
        return count
    }

    private shouldDeleteRow(y: number): boolean {
        for(let x = 0; x < this.dim.width; x++) {
            if(this.getAt(x, y) === null) { return false }
        }
        return true
    }

    private shiftRow(y: number) {
        for(let x = 0; x < this.dim.width; x++) {
            this.putAt(x, y + 1, this.getAt(x, y))
        }
    }
}