import { Tetrimino, newTetriminoType } from "./tetriminos";

type T = Tetrimino

function newTetrimino() {
    return new Tetrimino(newTetriminoType())
}

export class Queue {
    private held: T | null
    private current: T
    private next: T
    private second: T
    private third: T
    private forth: T

    getCurrent() {
        return this.current
    }
    
    constructor() {
        this.held = null
        this.current = newTetrimino()
        this.next = newTetrimino()
        this.second = newTetrimino()
        this.third = newTetrimino()
        this.forth = newTetrimino()
    }

    updateQueue() {
        this.current = this.next
        this.next = this.second
        this.second = this.third
        this.third = this.forth
        this.forth = newTetrimino()
    }
}