import { Matrix } from "./matrix";
import { getPos } from "./util";
import { Field } from "./field";

const tetoriminos = {
    i: Matrix.fromArray<1|0>(4, 
        [0, 0, 0, 0,
        1, 1, 1, 1]),
    o: Matrix.fromArray<1|0>(4, 
        [1, 1, 0, 0, 
        1, 1, 0, 0]),
    s: Matrix.fromArray<1|0>(4, 
        [0, 1, 1, 0, 
        1, 1, 0, 0]),
    z: Matrix.fromArray<1|0>(4, 
        [1, 1, 0, 0, 
        0, 1, 1, 0]),
    j: Matrix.fromArray<1|0>(4, 
        [1, 0, 0, 0, 
        1, 1, 1, 0]),
    l: Matrix.fromArray<1|0>(4, 
        [0, 0, 1, 0, 
        1, 1, 1, 0]),
    t: Matrix.fromArray<1|0>(4, 
        [0, 1, 0, 0, 
        1, 1, 1, 0]),
}

export type tetrimino_type = 'i' | 'o' | 's' | 'z' | 'j' | 'l' | 't' | null

export function newTetriminoType(): keyof typeof tetoriminos {
    const keys = Object.keys(tetoriminos)
    return keys[Math.floor(Math.random() * keys.length)] as keyof typeof tetoriminos
}

export class Tetrimino {
    private matrix = new Matrix<1 | 0>({width: 5, height: 5})
    private pos: {x: number, y: number} = {x: 4, y: -1}
    private rotation: 0 | 90 | 180 | 270 = 0
    private matrix_origin: {x: number, y: number} = {x: 2, y: 2}
    constructor(public readonly type: keyof typeof tetoriminos) {
        for(const {x, y, value} of tetoriminos[type].zipWithIndex()) {
            this.matrix.putAt(x + 1, y + 1, value)
        }
    }

    private matrixCoordToGlobalCoord(x: number, y: number): [number, number] {
        return [x + this.pos.x - this.matrix_origin.x, y + this.pos.y - this.matrix_origin.y]
    }

    render(parent: Element) {
        for(const {x, y, value} of this.matrix.zipWithIndex()) {
            if(!value) { continue }
            const [realX, realY] = this.matrixCoordToGlobalCoord(x, y)
            const e = parent.querySelector(`.tetris_block[data-pos="${realX}-${realY}"]`)
            if(e) {
                e.setAttribute("data-kind", this.type)
            }
        }

    }

    hardDrop(field: Field): { gameover?: boolean } {
        while(true) {
            const {settled, gameover} = this.move(field, 'down')
            if(settled) { break }
            if(gameover) { return { gameover: true } }
        }
        return {}
    }

    move(field: Field, direction: 'down' | 'right' | 'left'): {settled?: boolean, gameover?: boolean} {
        const prev = {...this.pos}
        switch(direction) {
            case 'down': this.pos.y += 1; break;
            case 'right': this.pos.x += 1; break;
            case 'left': this.pos.x -= 1; break;
        }
        if(!this.checkBound(field)) {
            this.pos = prev
            if(direction === 'down') {
                this.settle(field)
                if(this.pos.y < 0) { return { gameover: true} }
                return { settled: true }
            }
        }
        return { settled: false }
    }

    rotate(field: Field): { settled?: boolean, gameover?: boolean } {
        const prev = this.matrix.createCopy()
        for(const {x, y, value} of prev.zipWithIndex()) {
            this.matrix.putAt(4 - y, x, value)
        }
        if(!this.checkBound(field)) {
            this.matrix = prev
        }
        return { settled: false }
    }

    checkBound(field: Field) {
        for(const {x, y, value} of this.matrix.zipWithIndex()) {
            if(!value) { continue }
            const [gX, gY] = this.matrixCoordToGlobalCoord(x, y)
            if(field.getAt(gX, gY)) { return false }
            if(!field.checkRange(gX, gY)) { return false }
        }
        return true
    }

    settle(field: Field) {
        for(const {x, y, value} of this.matrix.zipWithIndex()) {
            if(!value) { continue }
            const [gX, gY] = this.matrixCoordToGlobalCoord(x, y)
            field.putAt(gX, gY, this.type)
        }
    }
}
