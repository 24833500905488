export class Matrix<A> {
    private array: A[]
    constructor(readonly dim: Readonly<{width: number, height: number}>) {
        this.array = Array(dim.height * dim.width)
    }

    getAt(x: number, y: number): A {
        return this.array[y * this.dim.width + x]
    }

    putAt(x: number, y: number, a: A) {
        this.array[y * this.dim.width + x] = a
    }

    *zipWithIndex() {
        for(let y = 0; y < this.dim.height; y++) {
            for(let x = 0; x < this.dim.width; x++) {
                yield {x, y, value: this.getAt(x, y)}
            }
        }
    }

    createCopy() {
        const c = new Matrix<A>(this.dim)
        c.array = [...this.array]
        return c
    }

    static fromArray<A>(width: number, arr: A[]) {
        const height = Math.ceil(arr.length * 1.0 / width)
        const a = new Matrix<A>({width, height})
        for(let i = 0; i < arr.length; i++) {
            a.array[i] = arr[i]
        }
        return a
    }
}