import { Field } from './field';
import { Queue } from './queue';

export class Game {
    private field: Field = new Field({height: 20, width: 10})
    private queue = new Queue()
    private gameover: boolean = false;

    constructor(private dom: Element) {
        this.field.initDOM(dom)
        this.queue.getCurrent().render(dom)
    }

    getGameOver() {
        return this.gameover
    }

    restart() {
        this.field = new Field({height: 20, width: 10})
        this.queue = new Queue()
        this.gameover = false
        this.update()
    }

    update() {
        if(this.gameover) { 
            this.dom.setAttribute('data-gameover', "true")
        } else {
            this.dom.removeAttribute('data-gameover')
        }
        this.field.updateDOM(this.dom)
        this.queue.getCurrent().render(this.dom)
    }

    hardDrop() {
        if(this.gameover) { return }
        const { gameover } = this.queue.getCurrent().hardDrop(this.field)
        if(gameover) {
            this.gameover = true
            this.update()
            return
        }
        this.field.deleteRows()
        this.queue.updateQueue()
        this.update()
        this.move('down')
    }

    move(direction: 'down' | 'right' | 'left') {
        if(this.gameover) { return }
        const {settled, gameover} = this.queue.getCurrent().move(this.field, direction)
        if(gameover) {
            this.gameover = true
            this.update()
            return
        }
        if(settled) {
            this.field.deleteRows()
            this.queue.updateQueue()
        }
        this.update()
    }

    rotate() {
        if(this.gameover) { return }
        const {settled, gameover} = this.queue.getCurrent().rotate(this.field)
        if(gameover) {
            this.gameover = true
        }
        this.update()
    }
}